import React from "react"
import { Link } from "gatsby"
import { useTransition, animated } from "react-spring"
import { connect } from "react-redux"
import ArrowRight from "./arrow-right"
import { ctaIsVisible } from "../store/selectors"

const StickyCTA = ({ isVisible }) => {
  const transitions = useTransition(!isVisible, null, {
    from: {
      transform: "translate3d(0, 100%,0)",
    },
    enter: {
      transform: "translate3d(0,0px,0)",
    },
    leave: {
      transform: "translate3d(0,100%,0)",
    },
  })

  return (
    <>
      {transitions.map(
        ({ item, props, key }) =>
          item && (
            <animated.div
              className="bg-white fixed-bottom py-3 px-md-3"
              style={{
                ...props,
              }}
              key={key}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="mr-3">
                        <p className="m-0">Voordelig en kwalitief verhuizen?</p>
                        <b>Vraag nu vrijblijvend een offerte aan</b>
                      </div>
                      <Link to="/offerte" className="btn btn-primary btn--sm">
                        <span className="d-none d-md-block">Offerte</span>
                        <span className="d-block d-md-none ml-n2">
                          <ArrowRight></ArrowRight>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          )
      )}
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    isVisible: ctaIsVisible(state),
  }
}

export default connect(mapStateToProps)(StickyCTA)
