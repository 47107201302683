import React from "react"
import styled from "@emotion/styled"
import classNames from "classnames/bind"
import PropTypes from "prop-types"
import logo from "../images/logo/verhuisdeal-24-logo.svg"
import truck from "../images/misc/truck-with-boxes.svg"
import ArrowDown from "./arrow-down"
import OfferteLink from "./offerteLink"

const StyledImg = styled.img`
  height: 32px;
  width: auto;
`

const StyledArrowDown = styled(ArrowDown)`
  height: auto;
  width: 60px;
`

const Truck = styled.img`
  height: 140px;
  width: auto;
  position: absolute;
  right: 16px;
  bottom: -78px;
`

function Banner({ variant }) {
  const rowClass = classNames({
    row: true,
    "justify-content-center text-center": variant,
    "justify-content-left text-left": !variant,
  })

  const colClass = classNames({
    "col-12": true,
    "col-md-8 col-lg-6": variant,
    "col-md-12": !variant,
  })

  const ctaClass = classNames({
    "banner__cta--centered": variant,
    "banner__cta--left": !variant,
  })

  return (
    <>
      <section className="section--md bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10">
              <StyledImg src={logo} alt="Logo" className="mb-3" />
              <h5 className="font-weight-light mb-1 mb-md-5">
                Verhuizen is een grote klus. Daarom doen wij ons best om de
                klanttevredenheid zo hoog mogelijk te houden. Voor een zorgeloze
                verhuisdienst tegen een betaalbare prijzen.
              </h5>
              <StyledArrowDown />
            </div>
          </div>
        </div>
      </section>

      <section className="section--truck bg-orange-gradient-300">
        <div className="container-fluid position-relative">
          <Truck src={truck} alt="Verhuizen met verhuisdeal24" />
          <div className={rowClass}>
            <div className={colClass}>
              {variant && (
                <>
                  <h5 className="font-weight-bold">
                    Bent u ook klaar voor een...
                  </h5>
                  <h4 className="font-weight-bold mb-1">
                    ...voordelige en kwalitatieve verhuizing?
                  </h4>
                </>
              )}
              {!variant && (
                <h4 className="font-weight-bold mb-1">
                  Bent u ook klaar voor een voordelige en kwalitatieve
                  verhuizing?
                </h4>
              )}
              <p className="mb-4">
                Vraag vrijblijvend een offerte aan en ontvang van ons het meest
                voordelige verhuisprijs voor uw verhuizing.
              </p>
              <div className={ctaClass}>
                <OfferteLink btnClass="btn-tertiair mb-2 mb-md-0"></OfferteLink>
                <a
                  className="link link-tertiair"
                  href="https://wa.me/31622441648?text="
                >
                  Of Whatsapp met ons
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

Banner.propTypes = {
  variant: PropTypes.bool,
}

Banner.defaultProps = {
  variant: false,
}

export default Banner
