// Icon
import React, { useEffect } from "react"
import VisibilitySensor from "react-visibility-sensor"
import { Link } from "gatsby"
import useId from "react-use-uuid"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import classNames from "classnames/bind"
import * as actionTypes from "../store/actions"

function OfferteLink({
  className,
  onChangeToVisible,
  onChangeToHidden,
  btnClass,
}) {
  const id = useId()

  useEffect(() => {
    return () => {
      onChangeToHidden(id)
    }
  }, [id, onChangeToHidden])

  const btnClasses = classNames({
    "btn btn-offer": true,
    [btnClass]: true,
  })

  return (
    <VisibilitySensor
      onChange={isVisible => {
        isVisible ? onChangeToVisible(id) : onChangeToHidden(id)
      }}
    >
      <Link to="/offerte" className={btnClasses}>
        Bereken jouw verhuizing
      </Link>
    </VisibilitySensor>
  )
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onChangeToVisible: uuid =>
      dispatch({
        type: actionTypes.ADD_CTA,
        uuid,
      }),
    onChangeToHidden: uuid =>
      dispatch({
        type: actionTypes.REMOVE_CTA,
        uuid,
      }),
  }
}

OfferteLink.propTypes = {
  btnClass: PropTypes.string,
}

OfferteLink.defaultProps = {
  btnClass: "btn-primary",
}

export default connect(null, mapDispatchToProps)(OfferteLink)
