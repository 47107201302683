import React from "react"
import styled from "@emotion/styled"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

const Wrapper = styled.figure``

function Testimonials({ testimonials, variant }) {
  return (
    <section className="d-none d-md-block section--testiomonials bg-orange-100 mb-md-5">
      <div className="container-fluid">
        {testimonials.map((testimonial, i) => {
          const rowClass = classNames({
            "row align-items-center ": true,
            "flex-row-reverse text-md-left": i % 2 === (variant ? 1 : 0),
          })

          const leftClass = classNames({
            "col-12": true,
            "col-md-6": i === 0,
            "col-md-5 offset-md-1": i === 1,
            "col-md-4 offset-md-2": i === 2,
          })

          const rightClass = classNames({
            "col-12": true,
            "col-md-5 offset-md-1": i === 0 || i === 1,
            "col-md-4 offset-md-1": i === 2,
          })

          return (
            <div key={testimonial.title} className={rowClass}>
              <div className={variant ? leftClass : rightClass}>
                <Wrapper className="testimonal__image">
                  {testimonial.image}
                </Wrapper>
              </div>
              <div className={variant ? rightClass : leftClass}>
                <h3 className="font-weight-light my-4">{testimonial.title}</h3>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
Testimonials.propTypes = {
  variant: PropTypes.bool,
}

Testimonials.defaultProps = {
  variant: false,
}

export default Testimonials
